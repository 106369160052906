<template>
  <div>
    <a-card title="希音上传记录">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-date-picker
            v-model="searchForm.sorting_date"
            valueFormat="YYYY-MM-DD"
            placeholder="分拣日期"
            style="width: 100%"
            @change="search"
          />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="运单号, 希音包裹号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.production_line" placeholder="生产线" allowClear style="width: 100%" @change="search">
            <a-select-option value="a">名创 A</a-select-option>
            <a-select-option value="b">名创 B</a-select-option>
            <a-select-option value="c">名创 C</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select
            v-model="searchForm.shein_upload_status"
            placeholder="上传状态"
            allowClear
            style="width: 100%"
            @change="search"
          >
            <a-select-option key="waiting" value="waiting">等待上传</a-select-option>
            <a-select-option key="success" value="success">上传成功</a-select-option>
            <a-select-option key="failure" value="failure">上传失败</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <!-- <a-col :span="24" style="width: 200px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入</a-button>
            </a-upload>
          </a-button-group>
        </a-col> -->

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            @change="changeTable"
          >
            <template slot="expandedRowRender" slot-scope="record">
              {{ record.shein_upload_detail }}
            </template>

            <template slot="footer">
              <a-space size="large">
                <span>等待上传: {{ statsItem.waiting_count }}</span>
                <span>上传成功: {{ statsItem.success_count }}</span>
                <span>上传失败: {{ statsItem.failure_count }}</span>
              </a-space>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { sheinUploadRecordList, sheinUploadRecordStats, sheinUploadTemplate, sheinUploadImport } from "@/apis/sorting";
import { exportExcel } from "@/utils/excel";

export default {
  data() {
    return {
      searchForm: {
        search: "",
        page: 1,
        ordering: undefined,
        sorting_date: undefined,
      },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: true,
      importLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "运单号",
          dataIndex: "tracking_number",
        },
        {
          title: "生产线",
          dataIndex: "production_line_display",
        },
        {
          title: "希音包裹号",
          dataIndex: "package_number",
        },
        {
          title: "包装方式",
          dataIndex: "packaging_code",
        },
        {
          title: "重量(kg)",
          dataIndex: "weight",
        },
        {
          title: "长(mm)",
          dataIndex: "length",
        },
        {
          title: "宽(mm)",
          dataIndex: "width",
        },
        {
          title: "高(mm)",
          dataIndex: "height",
        },
        {
          title: "上传状态",
          dataIndex: "shein_upload_status_display",
        },
        {
          title: "分拣时间",
          dataIndex: "sorting_time",
          width: 180,
        },
      ],
      dataItems: [],
      statsItem: {},
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      sheinUploadRecordList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });

      sheinUploadRecordStats({ ...this.searchForm, status: undefined }).then((data) => {
        this.statsItem = data;
      });
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    downloadTemplate() {
      sheinUploadTemplate().then((response) => {
        exportExcel(response, "希音回传导入模板");
      });
    },
    importExcel(item) {
      const data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      sheinUploadImport(data)
        .then(() => {
          this.$message.success("导入成功");
          this.list();
        })
        .finally(() => {
          this.importLoading = false;
        });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
